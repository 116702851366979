import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography/Typography';
import Grid from '@material-ui/core/Grid';
import FelonLogo from '../svg/FelonLogo';
import withRoot from '../withRoot';
import ImgLoader from './imageLoader';

const styles = theme => ({
	root: {
		boxShadow: '0px 15px 30px rgba(0, 0, 0, 0.25)',
		borderRadius: '5px',
		overflow: 'hidden',
		transition: 'box-shadow 0.2s ease',
		'&:hover': {
			boxShadow: '0px 20px 25px rgba(0, 0, 0, 0.3)'
		}
	},
	placeholder: {
		width: '100%',
		position: 'relative',
		paddingTop: '100%',
		color: theme.palette.type === 'light' ? theme.palette.primary.dark : theme.palette.primary.light,
	},
	placeholderGrid: {
		width: '100%',
		height: '100%',
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		padding: 8,
		backgroundColor: theme.palette.type === 'light' ? '#DCFFFF' : '#78B7BB'
	},
});

class Cover extends React.Component {

	render () {
		const {classes, release, imgName} = this.props;

		return (
			<div className={classes.root}>
				{imgName ?
					<ImgLoader imgName={imgName} ratio={1}/> :
					<div className={classes.placeholder}>
					<Grid container justify={'center'} className={classes.placeholderGrid}>
						<Grid item xs={12}>
							<Typography variant={'h4'} color={'inherit'}
							            align={'center'}>{release.name}</Typography>
						</Grid>
						<Grid justify={'center'} item container xs={12}>
							<Grid item xs={3}>
								<FelonLogo width={'100%'} height={'100%'}/>
							</Grid>
						</Grid>
					</Grid>
					</div>}

			</div>
		);
	}
};

Cover.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withRoot(withStyles(styles)(Cover));
