import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {graphql, StaticQuery} from 'gatsby';
import withRoot from '../withRoot';
import Img from 'gatsby-image';

const styles = theme => ({
	root: {
		position: "absolute",
		left: 0,
		top: 0,
		width: "100%",
		height: "100%"
	},
});

class ImageLoader extends React.Component {

	state = {
		pic: this.props.data.allImageSharp.edges.find(x => x.node.fluid.originalName === this.props.imgName),
	};

	render () {
		const {classes} = this.props;
		const aspRatio = this.props.ratio || this.state.pic.node.fluid.aspectRatio;

		const fluid = {...this.state.pic.node.fluid, aspectRatio: aspRatio};

		return (
			<Img className={classes.root} fluid={fluid}/>
		);
	}
};

ImageLoader.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withRoot(withStyles(styles)(props => (
	<StaticQuery
		query={graphql`
      query {
          allImageSharp {
            edges {
              node {
                fluid {
                  tracedSVG
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                  originalName
                }
              }
            }
          }
        }

    `}
		render={data => <ImageLoader data={data} {...props} />}
	/>)));
